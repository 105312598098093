import { getLoginFormData, LoginFormValues } from "@saas/account/utils";

import { kratos, triggerLogout } from "..";

export type ReloginOauthInput = {
  container: HTMLDivElement | null;
  data: LoginFormValues;
  loginChallenge: string;
};

export type ReloginOauthOutput = void;

/**
 * Relogin with oauth implemented through hidden
 * native form due to httpOnly cookie.
 */
export const reloginOauth = async ({
  container,
  data,
  loginChallenge,
}: ReloginOauthInput): Promise<ReloginOauthOutput> => {
  try {
    const token = await kratos.createBrowserLogoutFlow().then(({ data }) => {
      return data.logout_token;
    });

    await triggerLogout({ token });
  } catch {
    /* noop */
  }

  const loginForm = await kratos
    .createBrowserLoginFlow({
      loginChallenge,
    })
    .then(({ data }) => getLoginFormData(data));

  const formInput = {
    csrf_token: loginForm.csrfToken,
    method: loginForm.method.value,
    identifier: loginForm.identifier.value,
    password: loginForm.password.value,
  };

  if (!container) {
    throw new Error("Container is not found.");
  }

  const form = document.createElement("form");
  container.appendChild(form);

  form.action = loginForm.action;
  form.method = "POST";

  Object.entries(formInput).forEach(([key, value]) => {
    const input = document.createElement("input");
    input.type = "hidden";
    input.name = key;
    input.value = value;

    if (key === "identifier") {
      input.value = data.identifier;
    }

    if (key === "password") {
      input.value = data.password;
    }

    form.appendChild(input);
  });

  form.submit();
};

export default reloginOauth;
