import {
  ButtonHTMLAttributes,
  ComponentPropsWithoutRef,
  forwardRef,
  ReactNode,
  useState,
} from "react";

import { SVG } from "@saas/shared/icon";
import { classNames, useUniqueId } from "@saas/shared/utils";

import { Typography } from "../..";

export interface BaseInputProps extends ComponentPropsWithoutRef<"input"> {
  label: string;
  testid?: string;
  hint?: string | ReactNode;
  error?: boolean;
  action?: {
    icon: SVG;
    onClick?: () => void;
    testid?: string;
  };
  showLabel?: boolean;
  showCounter?: boolean;
  fixedLabel?: boolean;
  containerClassName?: string;
  hintClassName?: string;
  suffix?: string;
}

export const BaseInput = forwardRef<HTMLInputElement, BaseInputProps>(
  (
    {
      label,
      testid,
      hint,
      error,
      action,
      showLabel = true,
      showCounter = false,
      containerClassName,
      hintClassName,
      fixedLabel,
      suffix,
      ...props
    },
    ref
  ) => {
    const defaultValue = props.defaultValue ?? props.value;

    const id = useUniqueId(props.id ?? "input");
    const [value, setValue] = useState(defaultValue ?? "");

    const isCounterShown = showCounter && !!props.maxLength;

    const Icon = action?.onClick ? "button" : "span";

    const iconButtonProps: ButtonHTMLAttributes<unknown> =
      Icon === "button"
        ? {
            type: "button",
            onClick: action?.onClick,
            disabled: props.disabled,
          }
        : {};

    return (
      <div className={classNames(props.hidden && "hidden", containerClassName)}>
        <div
          className={classNames(
            "relative",
            props.type === "hidden" && "hidden"
          )}
        >
          <input
            {...props}
            id={id}
            type={props.type ?? "text"}
            ref={ref}
            placeholder={props.placeholder ?? "placeholder"}
            onChange={(e) => {
              setValue(e.target.value);
              props.onChange?.(e);
            }}
            className={classNames(
              "text-disabled border-neutral-N300 enabled:text-he peer block w-full rounded border p-4 text-base leading-6 placeholder-transparent enabled:focus:outline-none enabled:focus:ring-1",
              (action || suffix) && "pr-12",
              fixedLabel && "placeholder:text-le",
              error
                ? "enabled:border-red-R700 enabled:focus:ring-red-R700"
                : "enabled:focus:border-blue-B700 enabled:focus:ring-blue-B700",
              props.className
            )}
            aria-invalid={error}
            data-testid={testid}
            onWheel={(e) => e.currentTarget.blur()}
          />
          {suffix ? (
            <Typography
              className={classNames(
                "absolute inset-y-0 right-0 flex items-center pr-3 text-base",
                props.disabled ? "text-disabled" : "text-he"
              )}
            >
              {suffix}
            </Typography>
          ) : null}
          {action ? (
            <Icon
              {...iconButtonProps}
              className={"absolute inset-y-0 right-0 flex items-center pr-3"}
              data-testid={action.testid}
            >
              <span className={"sr-only"}>Icon</span>
              <action.icon
                className={"text-neutral-N600 h-6 w-6"}
                aria-hidden={"true"}
              />
            </Icon>
          ) : null}

          <label
            htmlFor={id}
            className={classNames(
              "peer-enabled:text-he peer-enabled:peer-focus:peer-placeholder-shown:text-he text-disabled bg-neutral-N0 pointer-events-none absolute inset-y-auto left-3 -top-2 inline-flex transform items-center rounded-sm px-1 text-[0.75rem] font-medium leading-[1rem] tracking-[0.5px] transition-all duration-100",
              !fixedLabel &&
                "peer-placeholder-shown:inset-y-0 peer-placeholder-shown:bg-transparent peer-placeholder-shown:text-base peer-placeholder-shown:font-normal peer-placeholder-shown:tracking-[0.15px]",
              !showLabel && "sr-only",
              error
                ? "peer-enabled:text-error peer-enabled:peer-placeholder-shown:text-he"
                : "peer-enabled:peer-focus:text-blue-B700"
            )}
          >
            <span>{label}</span>
          </label>
        </div>

        {hint || isCounterShown ? (
          <div
            className={classNames(
              "flex gap-4",
              isCounterShown && !hint ? "justify-end" : "justify-between"
            )}
          >
            {hint ? (
              <Typography
                as={"p"}
                type={"body-b3"}
                className={classNames(
                  "mt-1 ml-4",
                  error ? "text-red-R700" : "text-le",
                  hintClassName
                )}
                data-testid={`${testid}__error`}
                role={error ? "alert" : undefined}
              >
                {hint}
              </Typography>
            ) : null}

            {isCounterShown ? (
              <p
                className={classNames(
                  "body-b2 mt-1",
                  error ? "text-error" : "text-le"
                )}
              >
                {props.value || props.value === ""
                  ? props.value.toString().length
                  : value.toString().length}
                /{props.maxLength}
              </p>
            ) : null}
          </div>
        ) : null}
      </div>
    );
  }
);

BaseInput.displayName = "BaseInput";

export default BaseInput;
