import { QueryKey } from "@saas/account/config";
import { getLoginFormData, GetLoginFormDataOutput } from "@saas/account/utils";
import { FetchError, queryClient } from "@saas/core";
import { QueryOptionsInterface } from "@saas/shared/utils";

import { invalidateUseSessionQuery, kratos } from "../../..";

import { LoginFlow } from "@ory/client";
import { useQuery } from "@tanstack/react-query";

export type UseLoginQueryData = LoginFlow;

interface UseLoginQueryInput {
  refresh?: boolean;
}

export const useLoginQuery = <DerivedQueryData = UseLoginQueryData>(
  kratosOptions?: UseLoginQueryInput,
  options?: QueryOptionsInterface<UseLoginQueryData, DerivedQueryData>
) => {
  return useQuery<UseLoginQueryData, FetchError, DerivedQueryData>(
    [QueryKey.AUTH_LOGIN],
    () => {
      return kratos
        .createBrowserLoginFlow({ refresh: kratosOptions?.refresh })
        .then(({ data }) => data);
    },
    {
      select: options?.select,
      enabled: options?.enabled,
      onSuccess: async () => {
        await invalidateUseSessionQuery();
      },
      retry: false,
      refetchInterval: false,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false,
    }
  );
};

export const invalidateLoginQuery = () => {
  return queryClient.invalidateQueries([QueryKey.AUTH_LOGIN]);
};

export const useLoginFormQuery = (
  options?: QueryOptionsInterface<UseLoginQueryData, GetLoginFormDataOutput>
) => {
  return useLoginQuery(undefined, {
    ...options,
    select: (flow) => getLoginFormData(flow),
  });
};

export default useLoginQuery;
