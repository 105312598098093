import { getOryAttributes, GetOryAttributesOutput, getOryCsrf } from "../..";

import { LoginFlow } from "@ory/client";
import { UiText } from "@ory/client/api";

export type GetLoginFormDataInput = LoginFlow;

export type GetLoginFormDataOutput = {
  id: string;
  action: string;
  csrfToken: string;
  method: GetOryAttributesOutput | Record<string, never>;
  identifier: GetOryAttributesOutput | Record<string, never>;
  password: GetOryAttributesOutput | Record<string, never>;
  error?: UiText;
};

export const getLoginFormData = (
  input?: GetLoginFormDataInput
): GetLoginFormDataOutput => {
  if (!input) {
    throw new Error("LoginFlow is required");
  }

  const passwordNodes = input.ui.nodes.filter(
    (node) => node.group === "default" || node.group === "password"
  );

  return {
    id: input.id,
    action: input.ui.action,
    csrfToken: getOryCsrf(passwordNodes),
    method: getOryAttributes(passwordNodes, "method"),
    identifier: getOryAttributes(passwordNodes, "identifier"),
    password: getOryAttributes(passwordNodes, "password"),
    error: input.ui.messages?.[0],
  };
};

export default getLoginFormData;
