import { UiNode, UiText } from "@ory/client";
import { isUiNodeInputAttributes } from "@ory/integrations/ui";

export type GetOryAttributesOutput = {
  value: string;
  error: UiText;
};

export const getOryAttributes = (
  nodes: UiNode[],
  name: string
): GetOryAttributesOutput | Record<string, never> => {
  return nodes.reduce((node, current) => {
    if (
      isUiNodeInputAttributes(current.attributes) &&
      current.attributes.name === name
    ) {
      return {
        value: current.attributes.value as string,
        error: current.messages?.[0],
      };
    }

    return node;
  }, {});
};

export default getOryAttributes;
