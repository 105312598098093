import { fetchJson, ResponseInterface } from "@saas/core";
import { ResellerProfileInterface } from "@saas/marketplace-connection/utils";
import { mapToCamelCase } from "@saas/shared/utils";

export interface GetResellerProfileResponse extends ResponseInterface {
  data: {
    reseller_type: "individual" | "business";
    consent_correct_data: boolean;
    consent_tnc_agreement: boolean;
    individual: {
      id_card_name: string;
      id_card_number: string;
      id_card_address: string;
      id_card_image_url: string;
    };
  };
}

export type GetResellerProfileOutput = ResellerProfileInterface;

export const getResellerProfile = async (
  options?: RequestInit
): Promise<GetResellerProfileOutput> => {
  const response = await fetchJson<GetResellerProfileResponse>(
    "/api/marketplace/melaka-market/resellers/me",
    options
  );

  return mapToCamelCase(response.data);
};

export default getResellerProfile;
